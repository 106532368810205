@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
    font-family: niveau-grotesk-small-caps,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1c1f21;
}

button, select {
    color: #1c1f21;
    font-family: niveau-grotesk-small-caps,sans-serif;
}

select {
    appearance: none;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
    cursor: pointer;
}
